import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/gatsby';
import mixpanel from 'mixpanel-browser';

import { noLocalStorage } from './src/utils';

const siteId = 3719048;
const hotjarVersion = 6;

const BROWSER_EXTENSIONS = ['chrome-extension://', 'safari-extension://', 'arc-extension://', 'moz-extension://'];
const COOKIES_CONSENT_NAME = 'cookie-consent';

const cookieConsentManager = {
    // Initialize Google Analytics
    gtag: (command, targetId, config) => {
        if (window.gtag) {
            window.gtag(command, targetId, {
                ...config,
                anonymize_ip: noLocalStorage() || localStorage?.getItem(COOKIES_CONSENT_NAME) === 'declined',
            });
        }
    },
    // Initialize Facebook Pixel
    fbq: (command, targetId, config) => {
        if (window.fbq) {
            window.fbq(
                'consent',
                noLocalStorage() || localStorage?.getItem(COOKIES_CONSENT_NAME) === 'declined' ? 'revoke' : 'grant',
            );

            window.fbq(command, targetId, config);
            window.fbq('track', 'PageView');
        }
    },
    mixpanelInit: (token) => {
        mixpanel.init(token, {
            disable_cookie: noLocalStorage() || localStorage?.getItem(COOKIES_CONSENT_NAME) === 'declined',
        });
    },
};

export const onClientEntry = () => {
    Sentry.init({
        allowUrls: [/gethealthspan\.com/, /app\.gethealthspan\.com/],
        dsn: 'https://bd58775bd8820ea4b6804dab99556037@o4504968426029056.ingest.sentry.io/4506021749653504',

        // integrations: [Sentry.replayIntegration()],
        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysOnErrorSampleRate: 1.0,
        replaysSessionSampleRate: 0.1,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.5,

        beforeSend(event) {
            if (event.exception?.values) {
                // Iterate through each exception value
                for (const exception of event.exception.values) {
                    // Additionally, check the mechanism for 'injection'
                    if (exception.mechanism?.type === 'injection') return null;

                    // Check if an exception's stacktrace or mechanism indicates an extension
                    if (exception.stacktrace?.frames) {
                        for (const frame of exception.stacktrace.frames) {
                            if (frame.filename && BROWSER_EXTENSIONS.some((ext) => frame.filename.startsWith(ext))) {
                                // Returning null will discard the event
                                return null;
                            }
                        }
                    }
                }
            }

            return event;
        },
    });

    if (process.env.NODE_ENV === 'production') {
        cookieConsentManager.gtag('config', 'GTM-N7WK684');
        cookieConsentManager.fbq('init', '1426926401019091');
        cookieConsentManager.mixpanelInit('0f04a2813703e28e050bd2298f9f413b');
        Hotjar.init(siteId, hotjarVersion);
    } else {
        // eslint-disable-next-line
        console.log('Mixpanel and Hotjar are disabled for development');
    }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        console.log(`mixpanel.track('PageView', {
            path: ${location?.pathname},
            referrer: ${prevLocation ? prevLocation?.pathname : null},
        });`);
    } else {
        // hash or query changed
        if (location?.pathname === prevLocation?.pathname) {
            return;
        }

        mixpanel.track('PageView', {
            path: location?.pathname,
            referrer: prevLocation ? prevLocation?.pathname : null,
        });
    }
};
